import React, { useEffect, useState } from "react";
import "../styles/blog_template.css";
import PageTop from "../components/PageTop";
import { Link, useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import blogtpimg from "../Img/pt-bp.webp";
function Blog_Template(props) {
  const { id } = useParams()
  const [blogdata, setblogdate] = useState([])
  useEffect(() => {
    fetch();
  }, [])
  const fetch = () => {
    axios.get(`${props.url}/blogs/${id}`)
      .then(res => setblogdate(res.data))
      .catch()
  }
  return (
    <>
      {props.data.map((data, index) => { return (<div key={index}> <PageTop text={data.blogs} url={blogtpimg} alt="Blog Images" />  </div>) })}


      <div className="blog-template parent">
        <div className="blog-template-container container-small">
          <div className="blog-top">
            <div className="blog-img bg-img" style={{ background: `url(${blogdata.image})` }}></div>
            <div className="blog-stats">
              <div className="stats1 stats">
                <span className="property">Category</span>
                <h3>{blogdata.category}</h3>
              </div>

              <div className="stats2 stats">
                <span className="property">Published date</span>
                <h3>{blogdata.date}</h3>
              </div>

              <Link to='/blogs' className="btn-active">
                All Blogs
                <span>
                  <FaArrowRight />
                </span>
              </Link>
            </div>
          </div>

          <div className="blog-bottom">
            <h1>{blogdata.title}</h1>
            <div className="line"></div>
            <span dangerouslySetInnerHTML={{ __html: blogdata.blog_content }} />
          </div>
        </div>
      </div>

    </>
  );
}

export default Blog_Template;
