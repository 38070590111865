import React, { useEffect, useState } from "react";
import "../styles/blog.css";
import PageTop from "../components/PageTop";
import { CiSearch } from "react-icons/ci";
import LargeBlogCard from "../components/LargeBlogCard";
import SmallBlogCard from "../components/SmallBlogCard";
import blogtpimg from "../Img/pt-bp.webp";
import { Helmet } from "react-helmet";
import axios from "axios";

const Blog = (props) => {
  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [blogdata, setBlogdata] = useState([]);
  const [loadmore, setLoadmore] = useState(5);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFocus = () => {
    setInputType("date");
  };

  const handleBlur = () => {
    setInputType("text");
  };

  const handleFocus1 = () => {
    setInputType1("date");
  };

  const handleBlur1 = () => {
    setInputType1("text");
  };

  useEffect(() => {
    setSearchTerm("");
    setFromDate("");
    setToDate("");
    setIsSearching(false);
    setSearchResults([]);
    fetchBlog(
      `${props.url}${props.state ? "/blogs/category/6/" : "/blogs/category/4/"}`
    );
  }, [props.url, props.state]);

  const fetchBlog = (baseurl) => {
    axios
      .get(baseurl)
      .then((res) => setBlogdata(res.data))
      .catch();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
    setFromDate("");
    setToDate("");
    setIsSearching(false);
    setSearchResults([]);
  };

  const filterData = () => {
    const filteredData = blogdata.reverse().filter((data) => {
      const titleMatch = data.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const dateMatch =
        !fromDate || !toDate || (data.date >= fromDate && data.date <= toDate);
      return titleMatch && dateMatch;
    });

    setSearchResults(filteredData);
    setIsSearching(true);
  };

  return (
    <>
    <Helmet>
      <title>Akka Foundation- A non profit organization. </title>
      <meta name="keywords" content="akkafoundation, women empowerment, helping hand for farmers, free education, health realted programs, water saving, tree planting, project anandi, drushti abhiyan" />
      <meta name="description" content="Akka Foundation empowers women, supports farmers, offers free education, health programs, water saving, tree planting, and initiatives like Project Anandi and Drushti Abhiyan, fostering community well-being" />
    </Helmet>
 {props.data.map((data)=>{return(<><PageTop text={data.blogs} url={blogtpimg}/>
      <div className="searchbox-wrapper parent">
        <h2>
          <span>{data.searchblog}</span>
        </h2>
        <div className="searchbox-container container-small">
          <div className="searchbox">
            <span>
              <CiSearch />
            </span>
            <input
              type="text"
              placeholder="Search Blogs"
              onChange={handleSearch}
              onKeyUp={filterData}
              value={searchTerm}
            />
          </div>
          <div className="search-tools">
            <div className="blog-input-box">
              <input
                type={inputType}
                placeholder="Start Date"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type={inputType1}
                placeholder="End Date"
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="blog-btn-box">
              <button className="btn-unactive" onClick={handleReset}>
                Reset
              </button>
              <button className="btn-active" onClick={filterData}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      </>)})} 
      <div className="blogsresult-parent parent">
        <div className="blogsresult-parent parent">
          <div className="blogsresult-container container-small">
            {searchResults.length === 0 && isSearching ? (
              <div className="notfound">
                <p>No Blogs Found for the given search criteria.</p>
              </div>
            ) : (
              <>
                {searchResults.reverse().map((data, index) => (
                  <SmallBlogCard
                    key={index}
                    path={`/blogs/${data.id}`}
                    url={data.image.slice(data.image.indexOf("/media/") + 7)}
                    cat={data.category}
                    title={data.title}
                    date={data.date}
                  />
                ))}
              </>
            )}
            {!isSearching &&
              blogdata
                .slice()
                .reverse()
                .slice(0, loadmore)
                .map((data, index) => (
                  <SmallBlogCard
                    key={index}
                    path={`/blogs/${data.id}`}
                    url={data.image}
                    cat={data.category}
                    title={data.title}
                    date={data.date}
                  />
                ))}
            <button
              className="btn-active blog-end-btn"
              onClick={() => setLoadmore(loadmore + 5)}
            >
              Load More
            </button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
