import React, { useEffect, useState } from "react";
import "../styles/home.css";
import { Helmet } from "react-helmet";
import HomeCarousel from "../components/HomeCarousel";
import ComplexCard from "../components/ComplexCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Tilt } from "react-tilt";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import LargeBlogCard from "../components/LargeBlogCard";
import SmallBlogCard from "../components/SmallBlogCard";
import ProgressCard from "../components/ProgressCard";
import Homegallery from "../components/Homegallery";
import HookSection from "../components/HookSection";
import Footer from "../components/Footer";
import womenemp from "../Img/gallery-page/50.webp"
import girleducation from "../Img/gallery-page/27.webp"
import healtheducation from "../Img/gallery-page/41.webp"
import shiv from "../Img/gallery-page/44.webp"
import environment from "../Img/gallery-page/environment.webp"
import  axios  from "axios";
const Home = (props) => {
  const [blogdata,setblogdata] = useState([])
  useEffect(()=>{
    fetchData(`${props.url}` + `${props.state ? "/blogs/category/6/" : "/blogs/category/4/"}`);
  },[props.state])
  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };
  const fetchData = (baseurl) => {
    axios
      .get(baseurl)
      .then((res) => setblogdata(res.data))
      .catch();
  };
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
      <title>Empowering Lives: Akka Foundation's Mission in Education, Charity, and Community Support </title>
      <meta name="description" content="Discover Akka Foundation – a charity dedicated to free education, Drushti Abhiyan, Project Anandi, and supporting farmers with free food. Join us in making a difference and fostering positive change in the lives of others."  />
      <meta name="keywords" content="akka, akkafoundation, charity, non-profit organization, free education, drushti abhiyan, project anandi, free food, caring farmers " />
      <link rel="canonical" href="https://akkafoundation.in/home/" />
    </Helmet>
            <div className="home-carousel-container">
              <HomeCarousel data={props.data} />
            </div>

            <div className="about-parent parent">
              <div className="about-container container-small">
                <div className="about-left">
                  <Tilt options={defaultOptions}>
                    <div className="box-parent">
                      <div className="box-bg bg-img"></div>
                      <div className="smallbox bg-img"></div>
                      <div className="blurbox glass">
                        <div className="accent-div"></div>
                      </div>
                      <div className="bigbox bg-img"></div>
                    </div>
                  </Tilt>
                </div>
                <div className="about-right">
                  <h2>
                    <span>{data.HP_as_span} </span>
                    {data.HP_as_heading}
                  </h2>
                  <p>{data.HP_as_para}</p>
                  <Link to="/about" className="btn-active">
                    {data.HP_as_cta}
                    <span>
                      <FaArrowRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <ProgressCard url={props.url} data={props.data} />
            <div className="activity parent">
              <h2>
                {data.HP_as_title} <span></span>
              </h2>
              <div className="activity-container container-small">
                <Swiper
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1100: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide className="slide slide1">
                    <ComplexCard
                      url={womenemp}
                      title={data.HP_as_t1_title}
                      desc={data.HP_as_t1_desc}
                      btntext={data.HP_as_cta}
                      link="/empower"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="slide slide2">
                    <ComplexCard
                      url={girleducation}
                      title={data.HP_as_t2_title}
                      desc={data.HP_as_t2_desc}
                      btntext={data.HP_as_cta}
                      link="/education"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="slide slide3">
                    <ComplexCard
                      url={healtheducation}
                      title={data.HP_as_t3_title}
                      desc={data.HP_as_t3_desc}
                      btntext={data.HP_as_cta}
                      link="/environment"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="slide slide4">
                    <ComplexCard
                      url={environment}
                      title={data.HP_as_t4_title}
                      desc={data.HP_as_t4_desc}
                      btntext={data.HP_as_cta}
                      link="/health"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="slide slide5">
                    <ComplexCard
                      url={shiv}
                      title={data.HP_as_t5_title}
                      desc={data.HP_as_t5_desc}
                      btntext={data.HP_as_cta}
                      link="/art&culture"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <Homegallery data={props.data} setvideo={props.setvideo} setphoto={props.setphoto} setmedia={props.setmedia}/>
            <div className="latestblog-div parent">
              <h2>
                {data.HP_blog_title} <span>{data.HP_blog_span}</span>
              </h2>
              <div className="latestblog-container container-small">
               
              {blogdata
            .slice()
            .reverse()
            .slice(0,4)
            .map((data, index) => { return(
              <SmallBlogCard path={`/blogs/${data.id}`} url={data.image} cat={data.category} title={data.title} date={data.date} />
            )})}
            <Link to='/blogs' className="btn-active">View More</Link>
              </div>
            </div>
            <HookSection
              text={data.HP_ch_title}
              btn_text={data.HP_ch_cta}
              link="/contact"
              bgcolor="var(--darkbaiege)" 
            />
          </>
        );
      })}
    </>
  );
};

export default Home;
