import logo from "./logo.svg";
import "./App.css";
import About from "./pages/About";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimpleCard from "./components/SimpleCard";
import ComplexCard from "./components/ComplexCard";
import { useEffect, useState } from "react";
import AboutCarousel from "./components/AboutCarousel";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Togglebtn from "./components/toolbox/Togglebtn";
import english from "./content/English";
import marathi from "./content/Marathi";
import Footer from "./components/Footer";
import Empower from "./pages/Empower";
import NotFound from "./pages/Pagenotfound";
import Education from "./pages/Education";
import Environment from "./pages/Environment";
import Health from "./pages/Health";
import Art_and_culture from "./pages/Art_and_culture";
import "./styles/bugs.css"
import ProjectAnandi from './pages/ProjectAnandi';
import WaterConservation from "./pages/Water_conservation";
import JanjagarSanvad from "./pages/Janjagar_sanvad"
import Drushti_1 from "./pages/Drushti_1";
import Drushti_2 from "./pages/Drushti_2";
import Drushti_3 from "./pages/Drushti_3";
import Drushti_4 from "./pages/Drushti_4";
import Blog_Template from "./pages/Blog_Template"
import Drushti from "./pages/Drushti";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import Volunteer from "./pages/Volunteer";
import Contact from "./pages/Contact";
import Donatepage from "./pages/Donate";
import ScrollToTop from "./components/toolbox/Scroltotop";



function App() {
  const [photo,setphoto] =  useState(true)
  const [video,setvideo] =  useState(false)
  const [media,setmedia] =  useState(false)
  const [language,setlanguage] = useState(english)
  const [toggle,settoggle] = useState(false)
  const toggleJsonData = () => {
    setlanguage(language === english ? marathi : english);
    settoggle(!toggle)
  };
  const url = "https://api.akkafoundation.in/api"
  return (
    <div className="App">
      <BrowserRouter>
      <Togglebtn toggle={toggleJsonData}/>
        <Header setvideo={setvideo} setphoto={setphoto} setmedia={setmedia} data={language}/>
         <ScrollToTop />
         <Routes>
          <Route path="/about" element={<About  data={language}/>}/>
          <Route path="/gallery" element={<Gallery data={language} setvideo={setvideo} video={video} setphoto={setphoto} photo={photo} setmedia={setmedia} media={media}/>}/>
          <Route path="/" element={<Home state={toggle} url={url} setvideo={setvideo} setphoto={setphoto} setmedia={setmedia}  data={language} />}/>
          <Route path="/blogs" element={<Blog url={url} state={toggle} data={language}/>}/>
          <Route path="/empower" element={<Empower data={language}/>}/>
          <Route path="/education" element={<Education data={language}/>} />
          <Route path="*" element={<NotFound data={language}/>}/>
          <Route path="/environment" element={<Environment data={language} />}/>
          <Route path="/health" element={<Health data={language} />}/>
          <Route path="/art&culture" element={<Art_and_culture data={language} />}/>
          <Route path="/project-Anandi" element={<ProjectAnandi data={language} />}/>
          <Route path="/water-conservation" element={<WaterConservation data={language} />} />
          <Route path="/janjagar-sanvad" element={<JanjagarSanvad data={language} />} />
          <Route path="/drushti-1" element={<Drushti_1  data={language}  />}/>
          <Route path="/drushti" element={<Drushti data={language} />}/>
          <Route path="/drushti-2" element={<Drushti_2 data={language} /> } />
          <Route path="/drushti-3" element={<Drushti_3 data={language} /> } />
          <Route path="/drushti-4" element={<Drushti_4 data={language } /> } />

          <Route path="/privacy-policy" element={<Privacy data={language } /> } />
          <Route path="/cookies-policy" element={<Cookies data={language } /> } />
          <Route path="/volunteer" element={<Volunteer url={url} data={language } /> } />
          <Route path="/contact" element={<Contact url={url} data={language } /> } />
          <Route path="/donate" element={<Donatepage url={url} data={language } /> } />
          <Route path="/blogs/:id" element={<Blog_Template url={url} data={language} /> }/>

        </Routes>
        <Footer data={language}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
