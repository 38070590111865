const english = [
  {
    home: "Home",
    about: "About",
    ourworks: "Our Works",
    empowerment: "Empowerment",
    education: "Education",
    environment: "Environment",
    health: "Health",
    artculture: "Art & Culture",
    projectanandi: "Project Anandi",
    drustiabhiyan: "Drushti Abhiyan",
    waterconservation: "Jal Saksharta Rally",
    janjagarmanch: "Jan Jagar Sanvad",
    gallery: "Gallery",
    photos: "Photos",
    videos: "Videos",
    press: "Press & Media",
    blogs: "Blogs",
    contactus: "Contact us",
    searchblog:'Search Blogs',
    loadmore:'Load More',
    // home-page---------------------------------------
    
    HP_hs_s1_h: "A Hope For All: The Weak, The Women, The Uninformed. ",
    HP_hs_s1_desc :"A non-profit organisation working towards empowering the underprivileged sections of the society through education, healthcare and financial help.",
    HP_hs_s2_h: " Akka Foundation: An initiative blossomed from motherly care and courage. ",
    HP_hs_s2_desc :"7 years ago, our honourable Akka set up a vision for this organisation. The group continues to exist over generations, meeting the needs of the underprivileged.",
    HP_hs_s3_h: "Akka’s Foundation: A non-profit organisation providing sustainability to generations.",
    HP_hs_s3_desc :"Integrating those in need into society by providing them with opportunities and skills for a happy, healthy, and self-sustaining life.",
    HP_hs_s4_h: " Project Anandi: She’s entitled to know about herself, her health, and her future.",
    HP_hs_s4_desc :"Innovating approaches for menstrual health in rural areas—dispelling misconceptions, offering free pads, and fostering self-employment opportunities for rural women.",
    HP_hs_s5_h: "Dhrushti Abhiyan: Bringing light to the eyes eager to tell their story.",
    HP_hs_s5_desc :"'Drushti Abhiyan' initiative comes in as a campaign by the Akka Foundation to provide high-quality eye care to elderly rural citizens.",

    HP_as_heading:"A Non-Profit Commitment to Holistic Development.",
    HP_as_span:"Empowering Lives:",
    HP_as_para:"The Akka Foundation, a non-profit organisation, has been a family-driven force. Committed to holistic development, it empowers the underprivileged through opportunities, skills, and a supportive environment. From water security to cultural preservation, the foundation envisions a self-reliant and harmonious society, driven by its non-profit ethos.",
    HP_as_cta:"Get Involved",

    HP_cs_heading:"Lend a hand for a",
    HP_cs_heading_span:"Great Cause",
    HP_cs_pa_title:"Project Anandi",
    HP_cs_pa_desc:"Step into a world of change with Project Anandi – Her Pride, Our Duty. Project Anandi takes one step for her, tackles menstrual health challenges in rural areas, debunking misconceptions and distributing free sanitary pads to girls.",
    HP_cs_pa_current:"Current Progress",
    HP_cs_pa_target:"Target",
    HP_cs_pa_cta:"Donate Now",
    HP_cs_pa_learn:"Learn More",
    HP_cs_da_title:"Drushti Abhiyan",
    HP_cs_da_desc:"'Drushti Abhiyan' – A Vision for Eye Health by the Akka Foundation. This campaign addresses prevalent eye issues in rural India, particularly cataracts, offering free exams, consultations, glasses, and surgeries.",
    HP_cs_da_current:"Current Progress",
    HP_cs_da_target:"Target",
    HP_cs_da_cta:"Donate Now",
    HP_cs_da_learn:"Learn More",


    HP_as_title:"Bringing hope with acts of service",
    HP_as_cta:"Learn More",
    HP_as_t1_title:"Helping the needy in improving their lives. ",
    HP_as_t1_desc:"Empowerment at Akka Foundation transcends economic, cultural, societal, and national dimensions. We identify and act on opportunities for sustainable rural development, fostering linkages across sectors and uplifting communities through special schemes.",
    HP_as_t2_title:"Empowering Minds: A Classroom for Every Child.",
    HP_as_t2_desc:"In a nation with education challenges, Akka Foundation strives to bridge gaps. We support needy students, provide financial aid, run exam guidance centres, and advocate for equal opportunities, paving the way for a brighter future.",
    HP_as_t3_title:"Nurturing Health: Donations Transforming Lives Every Day.",
    HP_as_t3_desc:"Akka Foundation transforms rural health through routine check-ups, emergency services, and awareness campaigns. With your donations, we provide critical patient support, food assistance, and vital medical equipment, ensuring comprehensive well-being for all.",
    HP_as_t4_title:"Promoting a better tomorrow through green practices.",
    HP_as_t4_desc:"Akka Foundation leads environmental conservation, focusing on nature, food security, water, and soil conservation. From tree planting to recycling initiatives, we engage communities in sustainable practices for a greener future.",
    HP_as_t5_title:"Nurturing the heartbeat of rural life: Its art and culture.",
    HP_as_t5_desc:"Akka Foundation fosters cultural preservation, understanding unique challenges and aspirations. Through partnerships and open dialogues, we tailor strategies, respecting fundamental rights, traditions, and beliefs, promoting peaceful cooperation and harmony.",


    HP_og_title:"Capturing Moments:",
    HP_og_span:"A Visual Journey of Akka Foundation's Impactful Initiatives.",
    HP_og_1:"Photo Gallery",
    HP_og_2:"Video Gallery",
    HP_og_3:"News & Media",
    HP_og_4:"Our Work Up Close",



    HP_blog_title:"Read Our Stories:",
    HP_blog_span:"Akka Foundation's Blog Hub for Impactful Insights.",


    HP_ch_title:"Connect with us! Together, we make a difference in lives.",
    HP_ch_cta:"Contact Us",


    //about page


    AP_pt_title:"Akka Foundation’s Path To Holistic Change",

    AP_hc_title:"We Stand By You,",
    AP_hc_span:"Your Hopes And Your Future",
    AP_hc_desc:"In a world where many lack essentials, the Akka Foundation, a non-profit organisation, operates like a supportive family, providing more than just material aid. It's about offering opportunities, motivation, and skills, fostering independence. The foundation is committed to enhancing lives in the present and ensuring sustainability for the future. Addressing crucial issues such as water scarcity, education, and agricultural support, the foundation not only preserves cultural heritage but also empowers individuals for global competitiveness. With a vision for strength and equal opportunities, the foundation seeks to craft impactful solutions for significant challenges in impoverished communities, emphasising water and food security, equity, and women's empowerment. The Akka Foundation, with initiatives like Anandi and Drushti Abhiyan, marks just the beginning of its mission to create a better life for all, grounded in sustainability.",

    AP_ov_title:"Helping Lives: ",
    AP_ov_span:"Our Big Dream.",
    AP_ov_desc:"Our vision is to empower the underprivileged and guide them into the mainstream of life. Through opportunities and skill development, we aim to lead them towards self-sustainability. Get involved in our vision to give the best quality of life to those who lack access to basic amenities.",
   
    AP_oh_title:"Legacy of Hope:   ",
    AP_oh_span:" Akka Chronicles ",
    AP_oh_desc:"Embedded within the annals of Akka Foundation's history is the enduring legacy of Respected Akka, Smt. Rupatai Patil Nilangekar, a trailblazing leader devoted to sustainable social upliftment. Almost four decades ago, Reverend Akka initiated this profound journey unilaterally, devoid of formalities or support. Over the years, this endeavour evolved into an expansive family, galvanised by Akka's teachings, organising social and cultural events under her sagacious guidance. Recognizing the imperative of formalisation, the family metamorphosed into an organisation, embracing diverse sections of society along a progressive trajectory. The foundation's history embodies the ethos of solidarity, ceaseless progress, and an unwavering commitment to uplifting the needy and deprived. Akka's enduring legacy permeates, shaping a luminous future with a profound emphasis on sustainability for generations to come.  ",



    AP_om_desc_title:"Sustainability",
    AP_om_desc_title_span:"Our Impactful Mission",
    AP_om_desc_li1:"Elevate the unheard voices, emphasising the intrinsic worth of each individual, fostering a sense of importance and inclusivity for all.  ",
    AP_om_desc_li2:"Impart leadership skills to enhance lives, guiding individuals on the path to a fulfilling and joyful existence.  ",
    AP_om_desc_li3:"Uphold moral principles and ethical work practices, extending a helping hand to improve lives and contribute to a better world with sustainability at the core.  ",
    AP_om_desc_li4:"Adhere to health guidelines for the collective well-being, providing counsel for a healthier and safer life with a focus on sustainability.  ",
    AP_om_desc_li5:" Integrate contemporary and traditional concepts, respecting diverse cultures to ensure everyone feels part of the sustainable transformation.  ",
    AP_om_desc_li6:"Cultivate creativity and collaborative efforts, particularly in farming, with an eye on enduring sustainability.  ",
    AP_om_desc_li7:"Empower women in rural areas for global business, fostering strength and independence in a sustainable manner.  ",
    AP_om_desc_li8:"Analyse challenges related to finance, people, and the environment, innovating sustainable solutions for improvement. ",
    AP_om_desc_li9:"Harness new technology to enhance overall well-being, introducing fresh ideas for comprehensive and sustainable betterment.  ",


    AP_oi_title:"Simple living, high thinking: ",
    AP_oi_span:"Akka’s vision    ",
    AP_oi_desc:"In the saga of the Akka Foundation, the extraordinary 'Akka'  remains our beacon of hope. A compassionate, disciplined, and hardworking leader, she listened to the unspoken and uncovered potential where others saw despair. Guided by a vision for a harmonious future between nature and humanity, Akka selflessly implemented plans, addressing challenges at every level. Her unwavering commitment garnered the support of countless families, sparking inspiration in millions. The foundation, deeply moved by Akka's ethos of simplicity and profound thinking, sustains her transformative legacy. It uplifts families from hardship and shapes the destiny of generations. Akka's pragmatic problem-solving and wholehearted approach define our foundation, empowering women and embracing diverse social responsibilities with genuine care for sustainability. ",

    AP_founder:"Our",
    AP_founder_span:" Founders",
    AP_of_box1_title:"Sambhaji Patil Nilangekar",
    AP_of_box1_desc:"Sambhaji Patil Nilangekar, our esteemed founder at Akka Foundation, embodies the spirit of universal brotherhood inspired by Saint Dnyaneshwar. His parents imparted the sacred Sanskar, nurturing a culture of sustainable social work. Through initiatives like Drushti Abhiyan and Mahaarogya Abhiyan, Sambhaji's commitment, has positively impacted thousands of lives sustainably.",

    AP_of_box2_title:"Arvind Patil Nilangekar",
    AP_of_box2_desc:"Arvind Patil Nilangekar, Founder of Akka Foundation, began this transformative journey inspired by Akka's vision. Response to the water crisis in Latur evolved into a platform for public service, impacting lives in diverse fields. The Akka Foundation, under his guidance, is implementing numerous social activities in various parts of Latur district, fostering a sense of brotherhood. ",

    AP_of_box3_title:"Prajakta Ashish Marwaha",
    AP_of_box3_desc:"As an entrepreneur, brand builder, and philanthropist, she, leads the Akka Foundation with a passion for impactful connections and transformative initiatives. A discernment during the pandemic led her to be a part of the foundation. Beyond professional pursuits, she’s a devoted mother, navigating diverse experiences. Inviting hands to explore possibilities and transforming lives.  ",

    AP_slider_title:"Our",
    AP_slider_title_span:" Achievement",

    AP_btn_box:"View more",

    // page-top

    PT_gallery:"Gallery",




// empower page  

EM_pt_title:"Building Futures: Empowerment with Sustainability",
EM_hc_title:"Guiding hands, building strength, and unlocking limitless potential",
EM_hc_span:"",
EM_hc_desc:"Empowering the underprivileged lies at the core of Akka Foundation's mission for sustainable development. It entails providing sustainable opportunities, motivation, and resources to those facing economic and social challenges. By nurturing skills development and providing financial support, the foundation aims to make individuals self-reliant for a sustainable future.The focus is on achieving freedom of movement, economic security, familial support, and liberation from domination. Akka Foundation discerns opportunities and constraints for poverty reduction, concentrating on sustainable rural development. Interconnected sectors like agriculture, services, and manufacturing are linked to uplift communities. Tailored schemes target social, educational, and economic empowerment, from sustainable scholarships and skill training to rehabilitation programs. Empowering the underprivileged not only addresses immediate needs but also fosters long-term sustainability. By instilling self-reliance and cultivating a sense of worth and potential, Akka Foundation strives to create a more equitable and resilient society, championing sustainable development across all facets of life.",

EM_id1_title:"Empowering Women, Supporting Lives:",
EM_id1_span:"Akka Foundation's Impactful Initiatives",
EM_id1_desc:"In the tapestry of society, women stand as its essential support. Recognizing this, Akka Foundation dedicates itself to the authentic empowerment of women, particularly those from vulnerable backgrounds. The organisation tirelessly works towards skill development and the establishment of self-help groups in Latur district, ensuring that women benefit from both institutional structures and government schemes.",


EM_id2_title:"Independent",
EM_id2_span:"'HER'",

EM_id3_card1_title:"Empowering Women: Skill Development for Self-Reliance",
EM_id3_card1_desc:"In Nilanga taluka and across Latur district, our workshops are pivotal in empowering rural women. Through vocational training, we equip them with diverse skills, providing essential guidance and support. This initiative not only fosters self-reliance but also encourages women to venture into new businesses, creating a transformative impact on their lives and contributing to community development.",
EM_id3_card2_title:"Women's Savings Groups: For Financial Independence",
EM_id3_card2_desc:"Uniting rural women for financial self-reliance, the formation of savings groups becomes a beacon. Monthly savings habits are instilled through self-help groups, leading to mutual cooperation and collective benefits. Almost 5000 women have directly or indirectly reaped rewards from these groups, particularly aiding those aspiring to establish new industries.",



EM_id4_left_title:"Samvedna: ",
EM_id4_left_span:"Self-Reliant Disabled",
EM_id4_left_desc:"Amid the marginalised sections, people with disabilities face formidable challenges. Akka Foundation introduces the 'Samvedana' initiative, aiming to simplify and enrich the lives of disabled citizens, making them self-reliant. This includes registration, free distribution of essential materials, and employment opportunities for disabled individuals in Latur district. Additionally, concerted efforts are made to ensure they avail themselves of government schemes.",


EM_id4_right_title:"Work Done under",
EM_id4_right_span:"Samvedna Initiative",
EM_id4_right_para1:"Citizens with disabilities benefited from government schemes",
EM_id4_right_para2:"Free materials distributed amongs the citizens",


EM_id5_title:"A Helping Hand",
EM_id5_span:" For The Farmers.",


EM_id6_card1_title:"A helping hand for poor farmers",
EM_id6_card1_desc:"Akka Foundation acknowledges the struggles of Baliraja, the backbone of our sustenance. Focusing on alleviating their hardships, the organization extends a helping hand to needy farmers. Offering essential agricultural inputs such as seeds, materials, and financial assistance, Akka Foundation has supported over 1500 farmer brothers to date. Through this initiative, we aim to uplift and empower those who feed the world, fostering resilience in the agricultural community.",

EM_id6_card2_title:"Distribution of Livestock to Suicidal Farmer Families",
EM_id6_card2_desc:"In response to the distressing surge in farmer suicides, our organization takes a proactive stance, providing crucial livelihood support. The distribution of livestock emerges as a lifeline for families affected by this crisis, embodying the ethos of collective responsibility. This initiative not only offers tangible assistance but also symbolizes a commitment to restoring hope and stability to those grappling with the profound impact of farmer suicides.",

EM_id6_card3_title:"Empowering Farmers: Schemes and Aid Benefits",
EM_id6_card3_desc:"Collaborating with organizations and government support, Akka Foundation ensures farmers and their families receive vital guidance and assistance. The organization's dedicated volunteers are pivotal in this holistic approach. Through these initiatives, Akka Foundation consistently weaves a tapestry of positive change in the fabric of society, leaving a lasting impact on the lives of those it serves.",




EM_is_title:"Catch a glimpse of hands working for a better tomorrow.",


    //education page

    // EP --- Education page
    // pt --- page top
    // hc --- hero card
    // is --- independent section

    EP_pt_text: "Educating Minds, Transforming Futures Sustainably",
    EP_hc_title: "Laying the groundwork for a",
    EP_hc_span: "poverty-free tomorrow with accessible education.",
    EP_hc_desc: "In a country with over a billion people, only one-third can read due to challenges like a lack of basic facilities, resources, and teachers, along with insufficient public funds for education—creating a significant barrier to achieving sustainable development. Primary education in India is particularly neglected, posing difficulties for underprivileged communities and perpetuating generational challenges. Girls face additional hurdles, with issues stemming from caste, class, and gender differences, contributing to child labour and resistance to female education.Bringing sustainable education to the weaker sections is vital for breaking the cycle of poverty, empowering individuals, and fostering societal progress. Education equips people with essential skills, enhances employability, and broadens perspectives, enabling them to contribute meaningfully to the economy.",

    EP_is_card1_title:"Empowering Education and shaping future",
    EP_is_card1_span:"",
    EP_is_card1_desc:"Ensuring equal educational opportunities for every student is not just a goal but a fundamental right. With an unwavering commitment to preventing any student from being denied an education due to financial constraints, our organisation annually distributes free school materials to promising students in rural areas. To date, 4,600 students have benefited from this initiative.",

    EP_is_card2_title:"The New world of Digital Classroom",
    EP_is_card2_span:" ",
    EP_is_card2_desc:"In the pursuit of delivering modern and high-quality education to students in rural areas, our organisation champions digital classroom activities. This pioneering initiative, successfully piloted in selected schools across Latur district, has transformed learning experiences. With the integration of digital boards and computers, students now access better and contemporary education.",

    EP_is_card3_title:"Career Counseling Workshops for inspired minds",
    EP_is_card3_span:"",
    EP_is_card3_desc:"Guiding young minds towards the right career path is essential for their future success. Our institute conducts career counselling workshops, aiding students in making informed decisions about their professional journeys. Additionally, for those aspiring to excel in competitive exams, study materials are thoughtfully provided.",


    EP_is_card4_title:"Scholarships and financial aid building wings",
    EP_is_card4_span:"",
    EP_is_card4_desc:"Beyond materials and guidance, our institute stands as a pillar of support, offering scholarships and financial assistance to students in need. This crucial endeavor is driven by a sincere commitment to ensure that no future citizen of our country is deprived of education for any reason. ",


    EP_fi_title:"the Impact of Education on ",
    EP_fi_span:"Tomorrow's Leaders.",



    // environment page



    ENP_pt_title:"Green Harmony: Nurturing Nature for Sustainable Futures",

    ENP_hc_title: "Giving it back starts with nurturing ",
    ENP_hc_span: "nature and environment.",
    ENP_hc_desc:" In the intricate web of rural welfare, the environment stands as a linchpin for Akka Foundation, playing a pivotal role in fostering sustainable development. Recognizing the intimate connection between the environment and the well-being of rural communities, the foundation is committed to nurturing a harmonious coexistence. By prioritising environmental conservation, the foundation ensures that the delicate balance between nature and human activity is maintained.    Akka Foundation's initiatives focus on nature conservation, water security, rural development, soil conservation, and the promotion of environmentally friendly practices. Through tree planting campaigns, waste reduction programs, and sustainable agricultural practices, the foundation actively contributes to ecological health. A healthy environment not only ensures the physical well-being of communities but also underpins their economic and social resilience. Akka Foundation envisions a future where rural societies flourish in harmony with nature, contributing to the long-term sustainability and prosperity of the communities it serves.",


    ENP_is1_title:"Embracing Nature:",
    ENP_is1_span:"The Green Symphony",
    ENP_is1_desc:"Recognizing the unparalleled importance of trees in human life, our organisation has embraced 'tree planting' as a cherished and routine activity. Whether it's the birth anniversary of great heroes, various social events, or our organisation's own milestones, we commemorate these occasions by planting trees. Our dedicated volunteers take a solemn pledge to nurture and preserve these trees.",
    ENP_is1_line1:"Trees were planted and safeguarded.",
    ENP_is1_para2:"In our pursuit of a 'Green Latur, Prosperous Latur,' we consider the plantation and preservation of trees as crucial to sustaining life itself.",

    ENP_is2_title:"Saving Water,",
    ENP_is2_span:"Saving Lives",


    ENP_is3_title:"Indraprastha Jalbhumi Abhiyan: ",
    ENP_is3_span:"Latur's 'Jal Sanjeevani'",
    ENP_is3_desc1:"To transform Latur and Marathwada into 'drought-free' and water-rich regions, Akka Foundation launched the ",
    ENP_is3_desc2:"'Indraprastha Jal Bhumi Mission'",
    ENP_is3_desc3:" from May 22 to June 5, 2018. This comprehensive campaign involved training citizens across the district on water conservation through door-to-door outreach. Through material distribution and volunteer efforts, 2,800 borewells and wells were replenished, resulting in an impressive 8-metre rise in the district's groundwater level.",

    ENP_is4_card1_title:"Campaign Highlights",
    ENP_is4_card1_li1:"Implementation in 945 villages",
    ENP_is4_card1_li2:"Household water conservation training",
    ENP_is4_card1_li3:"Tree distribution to every home",
    ENP_is4_card1_li4:"Organizing cleanliness drives ",
    ENP_is4_card1_li5:"Recognizing citizens as 'Jalyoddha' post-training ",

    ENP_is4_card2_title:"Panchanishta initiatives:",
   ENP_card2_li1_is4:"Arresting flowing water",
    ENP_is4_card2_li2:"Channelizing running water",
    ENP_is4_card2_li3:"Rain water harvesting",
    ENP_is4_card2_li4:"Groundwater recharge",
    ENP_is4_card2_li5:"Planting trees for a sustainable future ",

    ENP_is5_title:"Jalyukta Shivar: ",
    ENP_is5_span:" Water-Rich Premises",
    ENP_is5_desc:" Contributing to the ambitious 'Jalyukt Shivar Abhiyan' by the Maharashtra Government, our organisation actively participated in bringing 'water prosperity' to the lives of Latur residents. This concerted effort has played a pivotal role in the success of the campaign across the entire district.",

    ENP_fi_title:"Environmental Initiatives",
    ENP_fi_span:"Crafting a Brighter Future.",





    //health page




    HEP_pt_title:"Healing Lives, Nurturing a Sustainable Tomorrow ",

    HEP_hc_title:"Delivering timely healthcare to the poor and needy, ",
    HEP_hc_span:"where it matters.",
    HEP_hc_desc:"Prioritising health is a fundamental aspect of Akka Foundation's mission for sustainable development among underprivileged communities. Health, being a cornerstone of individual and societal well-being, is integral for achieving long-term progress. For the underprivileged, health disparities exacerbate existing challenges, hindering overall development. Lack of access to preventive medicines, healthcare resources, and basic medical facilities compounds these issues, leading to preventable diseases and mortality. Investing in health creates a ripple effect, contributing to sustainable development in multifaceted ways. A healthy population is more productive, enabling economic growth and breaking the cycle of poverty. Akka Foundation's health initiatives, including routine check-ups, emergency services, and awareness campaigns, not only address immediate health concerns but also foster a culture of well-being. By integrating health into its holistic approach, the foundation envisions a future where the underprivileged lead healthier lives, contributing to the overall sustainability and resilience of the community. ",

    HEP_is1_card1_title: "Blood Donation: Gift of Life",
    HEP_is1_card1_desc: "Akka Foundation organizes blood donation camps aligned with influential birthdays, awareness days, and milestones. Hosting 50+ camps, 7,000 citizens generously contributed, addressing the perpetual need for blood in the medical field.",
    
    HEP_is1_card2_title: "Maha Aarogya Camp: Health for All",
    HEP_is1_card2_desc: "Prioritizing rural well-being, Akka Foundation conducts health camps, offering free check-ups to 1 lakh individuals. Over 10,000 citizens received permanent treatment, contributing to a healthier community.",
    
    HEP_is1_card3_title: "Successful Battle Against Corona",
    HEP_is1_card3_desc: "In response to the COVID-19 crisis, Akka Foundation actively engaged in public service. During these unprecedented times, the foundation provided assistance, contributing to the successful battle against the pandemic.",
    


    HEP_is2_card1_title:"Initiatives During the Corona Era",
    HEP_is2_card1_li1:"Distribution of food to 25,000 vulnerable citizens",
    HEP_is2_card1_li2:"Providing food to small businesses, impoverished patients, and their families through Annachatra",
    HEP_is2_card1_li3:"Distributing 5 BiPAP ventilators and 30 oxygen concentrators to hospitals",
    HEP_is2_card1_li4:"Establishing a over 100-bedded Covid Care Center",
    HEP_is2_card1_li5:"Setting up an independent 'NICU' for babies in anticipation of the third wave",
    HEP_is2_card1_li6:"Operating a war room, distributing sanitizers, arsenic album, and organizing blood donation camps to address blood shortages  ",
    HEP_is2_card1_li7:"Encouraging sugar mills to establish oxygen-generating plants",



    HEP_is2_card2_title:" Immunisation Taskforce: ",
    HEP_is2_card2_span:"A Lifeline for the Disabled",
    HEP_is2_card2_desc:"The 'Taskforce' program, a pioneering initiative by Akka Foundation during the Corona vaccination drive, involves 800 volunteers going door to door for vaccinations. This initiative has particularly benefited disabled citizens, with around 12,000 receiving vaccinations through the task force. Notably, Nagtirthawadi in Devani taluka achieved the remarkable milestone of being the first village in Marathwada and the second in the state to achieve 100% vaccination.",
    HEP_is2_card2_desc2:"Deputy Chief Minister Mr. Devendraji Fadnavis, acknowledging this commendable effort, praised the organisation through a virtual interaction.",

    HEP_fi_title:"Health Initiatives for a healthier Tomorrow.",





    //art&culture


    AC_pt_title:"Cultivating Culture, Sustaining Traditions, Empowering Communities",

    AC_hc_title:"Preserving the rural soul with its ",
    AC_hc_span:"art culture and traditions.",
    AC_hc_desc:"Art and culture form the vibrant tapestry of Akka Foundation's holistic approach to rural welfare, contributing profoundly to sustainable development. Recognizing the transformative power of artistic expression, the foundation integrates art and culture into its initiatives, fostering a sense of identity, pride, and unity within communities. Cultural preservation is crucial, as it anchors traditions, values, and heritage, providing a foundation for community resilience.    Through diverse programs, including festivals, workshops, and exhibitions, Akka Foundation not only celebrates local artistry but also facilitates cultural exchange, fostering mutual understanding. Art and cultural activities not only uplift spirits but also enhance mental well-being, promoting a sense of belonging. By incorporating these elements into its initiatives, the foundation envisions a future where rural communities thrive with a rich cultural identity, contributing to their sustainability, social cohesion, and overall development.Cultivating Culture, Sustaining Traditions, Empowering Communities",
    

    AC_is1_title:"World Record Shiv Jayanti: ",
    AC_is1_span:"A Tribute to Chhatrapati Shivaji Maharaj",
    AC_is1_desc:"Chhatrapati Shivaji Maharaj stands as a global icon, leaving an indelible mark on history with his visionary leadership. To perpetuate his legacy, Akka Foundation annually celebrates the World Record Shiv Jayanti, employing innovative concepts to pay homage to this legendary figure.",


    AC_slider1_title:"Year 2018: ",
    AC_slider1_span:"A Rangoli world Record",
    AC_slider1_li1:" An awe-inspiring image of Chhatrapati Shivaji crafted from 50 thousand kg of Rangoli. ",
    AC_slider1_li2:" Spread across 1 lakh 11 thousand 843 sq.ft on 2.5 acres of land.  ",
    AC_slider1_li3:" Witnessed by a crowd of one and a half lakh citizens. ",


    AC_slider2_title:"Year 2019: ",
    AC_slider2_span:"Harit Shiv Janmotsav ",
    AC_slider2_li1:" Introduced the concept of Harit Shivjanmotsav to raise awareness about the environment and agriculture. ",
    AC_slider2_li2:"Shiva Pratima created using living plants in a 6-acre farm through green grains.  ",
    AC_slider2_li3:" Magnificent expansion covering nearly 240 thousand sq.ft, recognized globally.  ",


    AC_slider3_title:"Year 2020: ",
    AC_slider3_span:"Shiv Sankalp for Drought Relief ",
    AC_slider3_desc:"Resolute is where action resides. Chhatrapati Shivaji's determination led to the establishment of 'Hindu Swarajya'. Shivaji Maharaj pledged to Hindu Swarjya by anointing his blood on the pindi of Rohideshwar. Making it momentous for the same, 'Shiva Jayanti' was celebrated by implementing an innovative initiative 'Shiva Sankalp' in 2020.",

    AC_slider4_title:"Year 2021 : ",
    AC_slider4_span:"Cultural Shiva Janmatsav    ",
    AC_slider4_li1:" Conducted two-day cultural programs amidst the backdrop of the pandemic.    ",
    AC_slider4_li2:"Distribution of masks and sanitisers for COVID-19 protection.   ",
    AC_slider4_li3:"Noteworthy events included 'Shivgarjana' by Avadhoot Gupte, Shiva Abhishek by helicopter, and a play by Bharat Jadhav, all adhering to COVID protocols.  ",
    AC_slider4_li4:"Shiva Abhishek and flower dropping by helicopter on Shiva Jayanti (19 Feb).",
    AC_slider4_li5:"A stage play 'Sahi re Sahi' by famous cinematographer Bharat Jadhav.",
    AC_slider4_li6:"Celebrating all events in a disciplined manner by following pandemic rules.",


    AC_slider5_title:"Year 2022 : ",
    AC_slider5_span:" Eternal Shiva Jayanti  ",
    AC_slider5_li1:" Embraced the concept of 'Eternal Shiv Jayanti' with a world-record oil painting of Chhatrapati Shivaji spanning 11,000 square feet.    ",
    AC_slider5_li2:"Registration of the painting in the Guinness Book of World Records.     ",
    AC_slider5_li3:"Organised a 'Blood Donation Mahayagyna,' with 585 Mawlas contributing.  ",
    AC_slider5_li4:"Unveiling of 'Chhatrapati Shivaji Maharaj Chowk' in Nilanga. ",
    AC_slider5_li5:"'Blood Donation Mahayagyna' was organized on the occasion of Shiva Jayanti, where over 585 people donated blood. ",
    AC_slider5_li6:"Unveiling of a new nameplate for the chowk in Nilanga, to be referred to as 'Chhatrapati Shivaji Maharaj Chowk'.",
    AC_slider5_li7:"Prof. Gangadhar Banbare's insightful lecture on 'Chhatrapati Shivaji: Beyond Battles' in the Ideology meeting.",
    AC_slider5_li8:"Planting trees across Nilanga village through the Gram Panchayat and other charity organizations.",


    AC_slider6_title:"Year 2023  : ",
    AC_slider6_span:"Jijauvandan Shiva Janmatsavam  ",
    AC_slider6_li1:"Introduced 'Jijau Vandana Shiva Janmotsav' to acknowledge the role of women, inspired by Rajmata Jijau Saheb.  ",
    AC_slider6_li2:"Featured a remarkable 81 thousand 393 sq. crop art of Chhatrapati Shivaji by feet.       ",
    AC_slider6_li3:"Lecture by Dr. Nandatai Patil on 'Me Jijau Boltey.'   ",

    AC_slider7_title:"Year 2024 : ",
    AC_slider7_span: "Shivjyot Janmotsav",
    AC_slider7_li1:
    "A magnificent fire art covering nearly 4lakh sqft. ",
    AC_slider7_li2:"Crafted by 100 skilled labourers with 2500kgs of marking rope. ",
    AC_slider7_li3:"World's largest fire art of Chhatrapati Shivaji Maharaj to date .",


    AC_slider_left_before_fi_title:"Bharat Ratna Dr. Babasaheb Ambedkar Jayanti",
    AC_slider_left_before_fi_desc:"Dr. Babasaheb Ambedkar had a profound impact on knowledge, unity, and patriotism. We organised various social activities to disseminate his thoughts, including an online essay competition called 'Vichardhara.'",


    AC_slider_right_before_fi_title:"Vichardhara  ",
    AC_slider_right_before_fi_desc:"Akka Foundation conducted an online essay competition 'Vichardhara' during the pandemic to propagate Dr. Babasaheb Ambedkar's ideas. Over 5000 contestants participated in the same statewide competition, with the winning essays compiled into a book.    ",


    AC_fi_tile:"Art and Culture ",
    AC_fi_tile_span:" ShUncover the aping Tomorrow's Vibrancy.",


    // volunteer------------page

    vp_pt_title:"Volunteer",
    vp_title:"Connect with us become a Volunteer.",
    vp_title_span:" Volunteer.",
    vp_title_desc:"Social organizations rely on enthusiastic volunteers to contribute time and skills across various programs, benefiting the community. Volunteering comes in different forms, such as skill-based, micro, emergency, and online volunteering. Your involvement supports community upliftment and national progress. By dedicating your time and skills, you provide hope to those in need. Volunteering offers diverse opportunities within and outside the organization, fostering networking and the exchange of ideas. It brings happiness and self-contentment, proving that you gain more satisfaction than you give.",

  
    

/// Drishti
DAM_pt_title:"Drushti Abhiyan ",
DAM_drushti_abhiyan1:"Drushti Abhiyan 1",
DAM_drushti_abhiyan2:"Drushti Abhiyan 2",
DAM_drushti_abhiyan3:"Drushti Abhiyan 3",
DAM_drushti_abhiyan4:"Drushti Abhiyan 4",


//water conservation
w_c_title:"Jal Saksharta Rally",
w_c_subtitle:"Let's save drop by drop, ",
w_c_span:"Let's save for the future! ",
w_c_desc:"More than 75 percent of people in Latur district depend on agriculture for their livelihood. About 8 lakh acres of the district is dryland. In addition, Latur district is a watershed (i.e. low-water) region, which faces severe water scarcity every year due to the plateau region, lack of forests and less and more rainfall due to environmental changes. Obviously, if water is insufficient for drinking and agriculture, where will water be available for industries? As a result of this, a large number of people, especially the young generation, are migrating from Latur today. A large number of villages are getting lost in the district. The 'Water Literacy Rally' was organized to educate the citizens about preventing all this and getting water to Latur, using what is there properly and fully, saving every drop of rainwater and using it.",
w_c_n_title:"Features of  ",
w_c_n_title1:" Rally:",
w_c_n_text:"19 September to 26 September 2023",
w_c_n_text1:"8 days",
w_c_n_text2:"10 talukas  ",
w_c_n_text3:"1,287 km bike ride.",
w_c_n_text4:"Visits to 354 villages ",
w_c_n_text5:"Support of 482 Gram Panchayats.",
w_c_n_text6:"Letter of support from 1,025 Ganesh Mandals.",

w_c_slider_title:"drifting in the sea,  ",
w_c_slider_span:" rightful water of Latur.",
w_c_slider_text1:"The state government has announced to give 167 TMC water to Marathwada. All this water will come to Marathwada through Godavari.",
w_c_slider_text2:"Meanwhile it has no provision for Manjra, Terna, Manyad and Lendi valleys of Latur.",
w_c_slider_text3:"Therefore, out of this 167 TMC of water, Latur's fair share of water should be determined and brought to Latur via Bhandardara. ",
w_c_slider2_title:"By removing the silt accumulated in the dams,  ",
w_c_slider2_span:" increase the water storage!",
w_c_slider_text4:"The storage capacity of the dams has decreased due to silt carried over the years. Therefore, the rightful water of Laturkars is being drained every year.",
w_c_slider_text5:"For this, the silt accumulated in the dams should be removed and the depth of the dams should be increased, so that the excess rain water accumulates in the dams.",
w_c_slider3_title:"In the district, ",
w_c_slider3_span:" repair of seepage lakes!",
w_c_slider_text6:"2000 percolation ponds have been created in the district to ensure that the farmers get water for 12 months. But due to lack of geological structure and repair, 198 TMC of water is carried away or evaporates from it every year.",
w_c_slider_text7:"To prevent this, all the seepage ponds should be surveyed and repaired. So that water will be available to farmers for permanent use along with ground water recharge.",

 //Janjagar Manch
 j_j_title:"Jan Jagar Samvad ",
 j_j_subtitle:"Thinking thoughts, ",
 j_j_span:" People's Development",
 j_j_desc:"Even after 75 years of independence, Marathwada has not fully developed. Today, many districts of Marathwada including Latur are struggling for basic things like drinking water. Where water is this level, what is the story of industry, employment, infrastructure. Developmental environment and employment opportunities are available in the district. Due to lack of it, displacement of youth and citizens started on a large scale. To prevent all this, a development forum called 'Jan Jagar Manch' has been established under the initiative and guidance of Mr. Sambhaji Patil Nilangekar for the overall development of Latur district. This forum decided to study the Israeli model and work on 9 sectors. A total of 450 experts and practitioners related to each of these 9 sectors, 50 from each to be brought together on a single platform to have a strategic discussion and thereby prepare a white paper for the development of the district on 12th August 2023 at Dayanand Auditorium, Latur on 'Jan Jagar Samvad' one day program. 9 separate sessions on 9 topics were conducted on this occasion. Also 9 separate white papers related to that area were prepared and read in front of everyone.",

 j_j_text1:"Agriculture",
 j_j_desc1:"Organizer: Hon. Sambhaji Patil Nilangekar",
 j_j_text2:"Education and skill development",
 j_j_desc2:"Organizer: Hon. Ajit Patil Quaker",
 j_j_text3:"Health",
 j_j_desc3:"Organizer : Hon.Dr. Lalasaheb Deshmukh",
 j_j_text4:"Water conservation",
 j_j_desc4:"Organizer: Hon. Ravindra Pathak",
 j_j_text5:"Employment and Industry",
 j_j_desc5:"Organizer: Hon. Uday Patil",
 j_j_text6:"Women and Child Development",
 j_j_desc6:"Coordinator: Hon. Prerna Honrao",
 j_j_text7:"Environment",
 j_j_desc7:"Organizer: Hon. Ramling Shere",
 j_j_text8:"Social Welfare and Security",
 j_j_desc8:"Convenor: Hon. Dr. Santosh Waghmare",
 j_j_text9:"Arts, Sports and Cultural",
 j_j_desc9:"Organizer: Hon. Adv. Shailesh Gojamgunde",

 j_j_maintitle:"9 areas selected for",
 j_j_span:" discussion",

// drusti-1
DA_pt_title:"Drushti Abhiyan ",
DA_title:"DRUSHTI ABHIYAN ",
DA_description:"Cataracts and eye diseases are high in rural areas due to apathy towards eye health and poor economic conditions. This disease is especially common in seniors and elderly citizens. As a permanent solution to this Hon. ‘Drushti Abhiyan’ was started with the initiative of Arvindbhaiya Patil Nilangekar. The cooperation of Akka Foundation as well as MLA of Nilanga Vidhan Sabha Constituency Hon. This activity was started to celebrate the birthday of Sambhajibhaiyya Patil Nilangekar. Through this thousands of citizens have got good ‘vision’ today.",

DA_counter_title:"Latest",
DA_counter_span:"Stats",

DA_village:"Villages",
DA_eye_check:"Free Eye Checkups",
DA_spectacles:"Free Spectacles",
DA_cataract:"Free Cataract Surgeries",

DA_featured_title:"Featured",
DA_featured_span:"Images",

//drushti 2.0
DA2_pt_title:"Drushti Abhiyan 2.0",
DA2_title:"Drushti Abhiyan 2.0 ",
DA2_description:"The second phase of the vision campaign was successfully carried out because many people liked it and wanted it to happen, especially those with special needs. We are happy that the campaign was a success. We started the campaign on December 25 to remember Atal Bihari Vajpayee's birthday, and we also celebrate Prime Minister Shri. Narendra Modi's birthday on September 17.",

//drushti 3.0
DA3_pt_title:"Drushti Abhiyan 3.0",
DA3_title:"Drushti Abhiyan 3.0 ",
DA3_description:"Following the successful organization of the first and second phases of the Drushti Abhiyan, Drushti Abhiyan 3.0 was organized on the occasion of Swami Vivekananda's birthday (12th January 2023). The planning for this phase was inspired by the birth anniversary of the revered Akka, scheduled to be held on 4th June 2023.",

//drushti 4.0
DA4_pt_title:"Drushti Abhiyan 4.0",
DA4_title:"Drushti Abhiyan 4.0 ",
DA4_description:"At the request of the people, the fourth phase of Drishti Abhiyan has also been started. This phase of the mission will run from June 20 to November 25, 2023. The needy citizens should take advantage of these camps in their villages.",





///======================================================



//beforefooter

    bf_tilte:"Join the ",
    bf_title_span:"Movement",
    bf_donate:"Donate",
    bf_contact:"Contact",
    bf_volunteer:"Volunteer",



//footer---------- 

   f_col1_title:"Our Work",
   f_col1_li2:"Empowerment",
   f_col1_li3:"Education",
   f_col1_li4:"Environment",
   f_col1_li5:"Health",
   f_col1_li6:"Art And Culture",
   f_col1_li7:"Project Anandit",
   f_col1_li8:"Drushti Abhiyan",

   f_col2_title:"Take Action",
   f_col2_li2:"Donate",
   f_col2_li3:"Volunteer",
   f_col2_li4:"Blogs",
   f_col2_li5:"Gallery",
   f_col2_li6:"About Us",
   f_col2_li7:"Contact Us",

   f_col3_title:"Legal",
   f_col3_li2:"Privacy Policy",
   f_col3_li3:"Cookies Policy",



   PNF_pt_title:"Oops page not found !",
   PNF_cta:"Back To Home",

    //project anandi
    PA_main_title:"Project Anandi",
    Pa_titles:"Project Anandi... ",
    Pa_spans:"Her Pride, our Duty",
    PA_sdisc:"An innovative initiative ‘Project Anandi’ was launched as a sustainable solution to the menstrual health problems of women and girls in rural areas. Through this initiative to remove misconceptions about menstruation in the society, providing free sanitary pads to girls. Also the objectives are to provide self-employment to rural women.",
    counter_title:"Latest",
    counter_span:"  Stats",
    counter_stat_1:"Schools",
    counter_stat_2:"Students",
    counter_stat_3:"Days",
    counter_stat_4:"workshop",



    //donate page 
    DP_food_title:"Project Anandi",
    DP_food_patient:"Drushti Abhiyan",
    DP_food_equipment:"Women Empowerment",
    DP_food_education:"Education",
    DP_food_health:"Health",
    donate_pt_title:"Donate Now",




  },
];
export default english;




