import React, { useEffect, useState } from "react";
import "../styles/Header.css";
import { Link } from "react-router-dom";
import logo from "../assets/Akka-Foundation.png";
import { FaArrowRight } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa";

function Header(props) {
  const [toggle1, settoggle1] = useState(false);
  const [toggle2, settoggle2] = useState(false);
  const [toggle, settoggle] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      settoggle1(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const photoHandler = () => {
    props.setmedia(false);
    props.setphoto(true);
    props.setvideo(false);
  };
  const videoHandler = () => {
    props.setmedia(false);
    props.setphoto(false);
    props.setvideo(true);
  };
  const mediaHandler = () => {
    props.setmedia(true);
    props.setphoto(false);
    props.setvideo(false);
  };
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <div className="header parent" >
              <div className="header-container container-large glass">
                <Link className="logo" to="/">
                  <img src={logo} alt="" />
                </Link>
                <div className="links">
                  <Link to="/">{data.home}</Link>
                  <Link to="/about"> {data.about}</Link>
                  <Link>
                    {data.ourworks}
                    <FaCaretDown />
                    <div className="submenu-parent">
                      <div className="submenu glass">
                        <Link to="/empower">{data.empowerment}</Link>
                        <Link to="/education">{data.education}</Link>
                        <Link to="/environment">{data.environment}</Link>
                        <Link to="/health">{data.health}</Link>
                        <Link to="/art&culture">{data.artculture}</Link>
                        <Link to="/project-Anandi">{data.projectanandi}</Link>
                        <Link to="/drushti">{data.drustiabhiyan}</Link>
                        <Link to="/water-conservation">{data.waterconservation}</Link>
                        <Link to="/janjagar-sanvad">{data.janjagarmanch}</Link>
                      </div>
                    </div>
                  </Link>
                  <Link to="/gallery">
                    {data.gallery}
                    <FaCaretDown />
                    <div className="submenu-parent">
                      <div className="submenu glass">
       
                        <Link to="/gallery" onClick={photoHandler}>
                          {data.photos}
                        </Link>
                        <Link to="/gallery" onClick={videoHandler}>
                          {data.videos}
                        </Link>
                        <Link to="/gallery" onClick={mediaHandler}>
                          {data.press}
                        </Link>
                        </div>
                      
                    </div>
                  </Link>
                  <Link to="/blogs">{data.blogs}</Link>
                  <Link className="btn-active" to="/contact">
                    {data.contactus}
                    <span>
                      <FaArrowRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="burger-menu"
              onClick={() => {
                settoggle1(!toggle1);
              }}
              aria-controls="primary-navigation"
              aria-expanded={toggle1 ? "true" : "false"}
            >
              <svg
                stroke="white"
                fill="none"
                className="hamburger"
                viewBox="-10 -10 120 120"
                width="50"
              >
                <path
                  className="line"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70"
                ></path>
              </svg>
            </div>

            <div className={toggle1 ? "mobile-nav-toggled" : "mobile-nav"}>
              <div
                className="mobile-right"
                onClick={() => settoggle1(false)}
              ></div>
              <div className="mobile-nav-container glass">
                <div className="phone-links">
                  <Link to="/" onClick={() => settoggle1(false)}>
                    {data.home}
                  </Link>
                  <Link
                    to="/about"
                    className="active"
                    onClick={() => settoggle1(false)}
                  >
                    {" "}
                    {data.about}
                  </Link>
                  <div
                    className="a"
                    onClick={() => {
                      settoggle(!toggle);
                      settoggle2(false);
                    }}
                  >
                    {data.ourworks}
                    <FaCaretDown />
                    <div className="mobile-submenu-parent">
                      <div className={toggle ? "submenu-accor" : "submenu-mob"}>
                        <Link to="/empower" onClick={() => settoggle1(false)}>
                          {data.empowerment}
                        </Link>
                        <Link to="/education" onClick={() => settoggle1(false)}>
                          {data.education}
                        </Link>
                        <Link
                          to="/environment"
                          onClick={() => settoggle1(false)}
                        >
                          {data.environment}
                        </Link>
                        <Link to="/health" onClick={() => settoggle1(false)}>
                          {data.health}
                        </Link>
                        <Link
                          to="/art&culture"
                          onClick={() => settoggle1(false)}
                        >
                          {data.artculture}
                        </Link>
                        <Link
                          to="/project-Anandi"
                          onClick={() => settoggle1(false)}
                        >
                          {data.projectanandi}
                        </Link>
                        <Link to="/drushti" onClick={() => settoggle1(false)}>
                          {data.drustiabhiyan}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Link
                    className="a"
                    onClick={() => {
                      props.setmedia(false);
                      props.setphoto(true);
                      props.setvideo(false);
                      settoggle2(!toggle2);
                    }}
                  >
                    {data.gallery}
                    <FaCaretDown />
                    <Link to='/gallery' className="mobile-submenu-parent">
                      <div
                        className={toggle2 ? "submenu-accor2" : "submenu-mob"}
                      >
                        <Link to="/gallery" onClick={photoHandler}>
                          {data.photos}
                        </Link>
                        <Link to="/gallery" onClick={videoHandler}>
                          {data.videos}
                        </Link>
                        <Link to="/gallery" onClick={mediaHandler}>
                          {data.press}
                        </Link>
                      </div>
                    </Link>
                  </Link>
                  <Link to="/blogs" onClick={() => settoggle1(false)}>
                    {" "}
                    {data.blogs}
                  </Link>
                  <Link
                    className="btn-active"
                    to="/contact"
                    onClick={() => settoggle1(false)}
                  >
                    {data.contactus}
                    <span>
                      <FaArrowRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Header;
